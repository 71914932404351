import { Link, useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import '../styles/Products.css'
import {Helmet} from 'react-helmet'

import FloatingWhatsApp from 'react-floating-whatsapp'
import logoRamsko from '../images/logo4.jpg'
import favicon from '../images/favicon.ico'



const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProducts {
        edges {
          node {
            productName
            productDetails {
              productDetails
            }
            productImage {
              file {
                url
              }
            }
            productSpecifications {
              file {
                url
              }
            }
            slug
          }
        }
      }
    }
  `)
  return (
    <div className='bg-white'> 
      <Layout>
      <Helmet title="Products | Ramasko | راماسكو"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Ramasko',
                    }
                ]}
        />
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>

        <div className='w-full'>
          
              <div className='flex justify-center xl:py-24 lg:py-20 sm:py-16 py-8'>
                <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:gap-6 lg:gap-5 md:gap-6 sm:gap-5 gap-4 xl:max-w-6xl 2xl:max-w-7xl lg:max-w-5xl md:max-w-3xl mx-auto px-4 sm:px-6'>
                {data.allContentfulProducts.edges.map((edge) =>{
                  return (
                    <Link to={`/Products/${edge.node.slug}`}><div className='productRectangleProducts bg-white'>
                      <div className='flex justify-center'><img className='productImageProducts' src={edge.node.productImage.file.url} alt='pro' /></div>
                      <div className='flex justify-center'><h1 className = "productNameProducts text-center pt-3 font-Almarai">{edge.node.productName}</h1></div>
                    </div></Link>
                  )
                })} 
                </div>
            </div>
        </div>
        <FloatingWhatsApp
          phoneNumber = "+966551161149"
          accountName = "راماسكو"
          statusMessage = "كتابة ..."
          chatMessage = "كيف يمكننا مساعدتك؟"
          avatar = {logoRamsko}
          allowClickAway
          allowEsc
          notificationSound = "true"
         />
      </Layout>
    </div>
  )
}

export default Products
